import { defineComponent } from 'vue';
import ConnectionStatusMixin from '@/mixins/connection-status';
import type { Checklist } from '@/models/checklist';

export default defineComponent({
  mixins: [ ConnectionStatusMixin ],
  props: [ 'id' ],
  computed: {
    checklist(): Checklist {
      const found = this.$store.state.checklists.find((checklist: Checklist) => checklist.id === Number(this.id));
      return found;
    }
  },
  methods: {
    sync() {
      if (this.checklist.shared && this.online) {
        this.$store.dispatch('syncChecklist', { checklistId: this.checklist.id });
      }
    }
  }
});
