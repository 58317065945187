import type { ChecklistDto } from '@/models/checklist-dto';
import type { ItemDto } from './models/item-dto';
import type { CategoryDto } from './models/category-dto';

interface ChecklistDtoDiff {
  deletedItems: ItemDto[];
  newItems: ItemDto[];
  modifiedItems: ItemDto[];
  deletedCategories: CategoryDto[];
  newCategories: CategoryDto[];
  modifiedCategories: CategoryDto[];
}

export function diff(from: ChecklistDto, to: ChecklistDto): ChecklistDtoDiff {
  const diff: ChecklistDtoDiff = {
    deletedItems: [],
    newItems: [],
    modifiedItems: [],
    deletedCategories: [],
    newCategories: [],
    modifiedCategories: [],
  };

  // Diff categories
  for(const fromCategory of from.categories) {
    if (!to.categories.find((toCategory: CategoryDto) => toCategory.id == fromCategory.id)) {
      diff.deletedCategories.push(fromCategory);
    }
  }

  for(const toCategory of to.categories) {
    const foundFrom = from.categories.find((fromCategory: CategoryDto) => fromCategory.id == toCategory.id);
    if (!foundFrom) {
      diff.newCategories.push(toCategory);
    } else if (foundFrom.name != toCategory.name) {
      diff.modifiedCategories.push(toCategory);
    }
  }

  // Diff items
  for(const fromItem of from.items) {
    if (!to.items.find((toItem: ItemDto) => toItem.id == fromItem.id)) {
      diff.deletedItems.push(fromItem);
    }
  }

  for(const toItem of to.items) {
    const foundFrom = from.items.find((fromItem: ItemDto) => fromItem.id == toItem.id);
    if (!foundFrom) {
      diff.newItems.push(toItem);
    } else if ((foundFrom.checked != null) != (toItem.checked != null)) {
      diff.modifiedItems.push(toItem);
    } else if ((foundFrom.highlighted != null) != (toItem.highlighted != null)) {
      diff.modifiedItems.push(toItem);
    }
  }

  return diff;
}

export function merge(base: ChecklistDto, mine: ChecklistDto, theirs: ChecklistDto): ChecklistDto {
  const mineDiff = diff(base, mine);

  //maybe needed for more complicated merges
  //const theirsDiff = diff(base, theirs);

  const result: ChecklistDto = {
    title: theirs.title,
    token: theirs.token,
    revision: theirs.revision,
    categories: theirs.categories.map(category => ({...category})).filter((category: CategoryDto) => !mineDiff.deletedCategories.find((mineCategory: CategoryDto) => mineCategory.id == category.id)),
    items: theirs.items.map(item => ({...item})).filter((item: ItemDto) => !mineDiff.deletedItems.find((mineItem: ItemDto) => mineItem.id == item.id))
  };

  // merge categories
  let lastCategoryId = Math.max(0, ...(theirs.categories.map((category: CategoryDto) => category.id)));

  for(const mineCategory of mineDiff.newCategories) {
    result.categories.push(mineCategory);
    mineCategory.id = ++lastCategoryId;
  }

  // merge items
  let lastItemId = Math.max(0, ...(theirs.items.map((item: ItemDto) => item.id)));

  for(const mineItem of mineDiff.newItems) {
    result.items.push(mineItem);
    mineItem.id = ++lastItemId;
  }

  for(const mineItem of mineDiff.modifiedItems) {
    const resultItem = result.items.find((item2: ItemDto) => item2.id == mineItem.id);
    if (resultItem) {
      if (mineItem.checked == null || resultItem.checked == null || Date.parse(mineItem.checked) < Date.parse(resultItem.checked)) {
        resultItem.checked = mineItem.checked;
      }
      if (mineItem.highlighted == null || resultItem.highlighted == null || Date.parse(mineItem.highlighted) < Date.parse(resultItem.highlighted)) {
        resultItem.highlighted = mineItem.highlighted;
      }
    }
  }

  return result;
}
