<template>
  <button class="btn btn-outline-secondary ms-3" @click="$router.push({ name: 'checklist-new' })">
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-plus" viewBox="0 0 16 16">
      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
    </svg>
    New
  </button>

  <div class="list-group list-group-flush my-4">
    <router-link
      class="list-group-item list-group-item-action"
      :class="{ active: checklist.id == activeId, 'list-group-item-secondary': checklist.id == activeId }"
      v-for="checklist in checklists"
      :to="{ name: 'checklist', params: { id: checklist.id } }"
      :key="checklist.id"
    >
      <span class="align-middle">{{ checklist.title }}&nbsp;</span>
      <svg v-if="checklist.shared" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="align-middle icon bi bi-cloud" viewBox="0 0 16 16">
        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
      </svg>

      <div class="float-end" v-if="checklist.id == activeId" @click.prevent="">
        <!-- Move up -->
        <button class="btn btn-outline-light btn-sm" @click="moveUp(checklist)">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-arrow-up" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
          </svg>
        </button>

        <!-- Move down -->
        <button class="btn btn-outline-light btn-sm ms-1" @click="moveDown(checklist)">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-arrow-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
          </svg>
        </button>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { Checklist } from '@/models/checklist';

export default defineComponent({
  props: { activeId: Number },

  methods: {
    moveUp(checklist: Checklist) {
      this.$store.dispatch("checklistMoveUp", { checklistId: checklist.id });
    },

    moveDown(checklist: Checklist) {
      this.$store.dispatch("checklistMoveDown", { checklistId: checklist.id });
    }
  },

  computed: {
    checklists() {
      return this.$store.state.checklists;
    },
  },
});
</script>
