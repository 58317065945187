<template>
  <nav-bar>
    <checklist-nav :id="id"></checklist-nav>
  </nav-bar>

  <div :style="{ 'margin-top': marginTop + 'px' }" class="container-fluid d-flex flex-column">
    <form @submit.prevent="addItem">
      <div class="input-group">
        <button type="button"
                v-if="speechRecognition"
                class="btn btn-outline-secondary"
                @click="startRecording"
                :disabled="isRecording">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-mic" viewBox="0 0 16 16">
            <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
            <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
          </svg>
        </button>

        <input
          class="form-control"
          type="text"
          v-model="itemName"
          :disabled="isRecording"
          v-focus
          autocomplete="off"
          placeholder="Name of new item"
          required
        />

        <button class="btn btn-outline-secondary" type="submit" :disabled="isRecording">Add</button>
      </div>
    </form>

    <!-- Binding key to loop option is necessary, because the swiper has to be recreated on loop change,
         otherwise there are console errors. (e.g. change from one category to multiple categories)
    -->
    <swiper
      :key="swiperOptions.loop ? 1 : 0"
      @swiper="onSwiper"
      @slide-change="onSwiperChanged"
      class="mt-2 flex-grow-1 w-100"
      v-bind="swiperOptions">
      <swiper-slide
          v-for="category in checklist.categories"
          :key="category.id">
        <transition-group tag="ul" class="list-group list-group-flush" name="list">
          <li v-for="item in items(category)"
              :key="item.id"
              :class="{ checked: item.checked != null, item: item.id >= 0, category: item.id < 0 }"
              class="list-group-item">
            <span v-if="item.id < 0" class="font-italic">{{ item.categoryName }}</span>
            <!-- prevent mousedown propagation, because we don't want the swipe of the slider to happen -->
            <div v-else v-on:mousedown.stop="" class="d-flex">
              <label>
                <input type="checkbox"
                      :checked="item.checked != null"
                      class="me-3 align-middle"
                      @click="toggleChecked(item.id)"
                      />
                <span class="align-middle">{{ item.name }}</span>
              </label>
              <label class="ms-auto">
                <input type="checkbox"
                      :checked="item.highlighted != null"
                      class="star"
                      @click="toggleHighlighted(item.id)"
                      />
              </label>
            </div>
          </li>
        </transition-group>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
/* eslint-disable no-console */
import { defineComponent } from 'vue';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import ConnectionStatusMixin from '@/mixins/connection-status';
import ChecklistMixin from '@/mixins/checklist';
import type { Category } from '@/models/category';
import NavBar from '@/components/nav-bar.vue';
import ChecklistNav from '@/components/checklist-nav.vue';

export default defineComponent({
  mixins: [ ConnectionStatusMixin, ChecklistMixin ],
  components: {
    Swiper,
    SwiperSlide,
    NavBar,
    ChecklistNav
  },
  data: function() {
    return {
      marginTop: 25,
      itemName: '',
      swiper: null as any,
      swiperOptions: {
        modules: [ Pagination ],
        runCallbacksOnInit: false,
        loop: false,
        initialSlide: 0,
        pagination: {
          el: '#swiper-pagination'
        },
      },
      swiperSyncing: false,
      speechRecognition: null as any,
      isRecording: false
    };
  },
  methods: {
    onSwiper(newSwiper : any) {
      this.swiper = newSwiper;
    },
    toggleChecked(itemId: number) {
      this.$store.dispatch('toggleChecklistItem', {
        checklistId: Number(this.id),
        itemId: itemId,
      });
      this.sync();
    },
    toggleHighlighted(itemId: number) {
      this.$store.dispatch('toggleHighlightChecklistItem', {
        checklistId: Number(this.id),
        itemId: itemId,
      });
      this.sync();
    },
    async addItem() {
      if (this.itemName !== '') {
        await this.$store.dispatch('addChecklistItem', {
          checklistId: Number(this.id),
          itemName: this.itemName,
          categoryId: this.checklist.currentCategoryId
        });
        this.sync();
        this.itemName = '';
      }
    },
    onSwiperChanged() {
      if (!this.swiperSyncing) {
        this.$store.dispatch('changeCurrentCategory', { checklistId: this.checklist.id, currentCategoryId: this.checklist.categories[this.swiper.realIndex].id });
      }
    },
    handleVisibilityChange() {
      if (!document.hidden) {
        this.sync();
      }
    },
    startRecording() {
      this.speechRecognition!.start();
    },
    items(category: Category): any[] {
      const visibleItems = this.checklist.items.filter(item => !item.checked || !this.checklist.hideCheckedItems);

      if (category.id == 1) {
        return this.checklist.categories.map(c => {
          const items = visibleItems.filter(item => item.categoryId == c.id).slice().reverse();
          return (c.id == 1 || items.length == 0) ? items : [{ id: -c.id, categoryName: c.name }, ...items];
        }).flat();
      } else {
        return visibleItems.filter(item => item.categoryId == category.id).slice().reverse();
      }
    }
  },
  computed: {
    currentCategoryId() {
      return this.checklist.currentCategoryId;
    },
  },
  mounted() {
    this.sync();

    document.addEventListener('visibilitychange', this.handleVisibilityChange, false);

    const w: any = window;
    const SpeechRecognition = (w.SpeechRecognition || w.webkitSpeechRecognition);

    if (SpeechRecognition) {
      const speechRecognition = new SpeechRecognition();
      this.speechRecognition = speechRecognition;
      speechRecognition.lang = 'de-DE';

      speechRecognition.onresult = (event: any) =>  {
        this.itemName = event.results[0][0].transcript;
      };

      speechRecognition.onstart = () => {
        this.isRecording = true;
        this.itemName = '';
      };

      speechRecognition.onend = () => {
        this.isRecording = false;
        if (this.itemName != '') {
          this.addItem();
        } else {
          alert('Speech not recognized!');
        }
      };
    }

    // TODO
    // placeholder for waiting
    // placeholder for not found
  },
  beforeUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  watch: {
    checklist: {
      immediate: true,
      deep: true,
      handler(newChecklist) {
        const multipleCategories = newChecklist.categories.length > 1;
        this.marginTop = multipleCategories ? 45 : 0;
        this.swiperOptions.loop = multipleCategories;
      }
    },
    currentCategoryId: {
      immediate: true,
      handler(newCategoryId) {
        try {
          this.swiperSyncing = true;
          const slideIndex = this.checklist.categories.findIndex(category => category.id == newCategoryId);
          if (this.swiper) {
            this.swiper.slideToLoop(slideIndex, 0, false);
          } else {
            this.swiperOptions.initialSlide = slideIndex;
          }
        } finally {
          this.swiperSyncing = false;
        }

        // trigger sync, to have an up-to-date checklist
        this.sync();
      }
    }
  }
});
</script>

<style scoped>
.item {
  padding: 0.40rem 1.25rem
}

.category {
  padding: 0px
}

.item input {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.item label {
  cursor: pointer;
  font-size: 14pt;
  line-height: 1;
  margin: 0px;
}

.checked {
  opacity: 0.5;
}

.checked span, .checked span:hover {
  text-decoration: line-through;
}

.swiper-slide {
  overflow-y: auto;
}

.list-leave-active {
  transition: all 1s;
}

.list-leave-to {
  opacity: 0;
}

.star {
  visibility: hidden;
  cursor: pointer;
  display: grid;
  place-content: center;
}

.star:before {
  content: "\2606";
  visibility: visible;
  font-size: 35px;
  color: lightgray;
  opacity: 0.5;
}

.star:checked:before {
  content: "\2605";
  color: gray;
  opacity: 1;
}
</style>
