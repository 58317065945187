<template>
  <div class="container-fluid">
    <div class="row">
      <button class="btn btn-success col ms-3 me-2" @click="join">Join</button>
      <button class="btn btn-secondary col ms-2 me-3" @click="$router.go(-1)">Cancel</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { ChecklistDto } from '@/models/checklist-dto';

export default defineComponent({
  props: [ 'token' ],
  methods: {
    async join() {
      const response: ChecklistDto = (await fetch('/api/v2/checklists/' + this.token).then(response => response.json()));
      const checklistId = await this.$store.dispatch('addChecklist', { title: response.title });
      this.$store.dispatch('setChecklistLastSync', { checklistId, checklistDto: response });
      this.$router.push({ name: 'checklist', params: { id: checklistId.toString() } });

      //console.log(response);
      //this.title = response.data.title;
      //this.items = response.data.items;
      //await axios.get('/api/v2/checklists/' + token);
    }
  }
});
</script>
