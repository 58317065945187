<template>
  <Teleport to="body">
    <div :id="id" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" :class="'btn btn-' + okVariant" data-bs-dismiss="modal" @click="ok">
              <slot name="modal-ok"></slot>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';

export default defineComponent({
  emits: ['ok'],
  props: {
    id: String,
    title: String,
    okVariant: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    ok() {
      this.$emit('ok');
    },
    show() {
      const modal = new Modal('#' + this.id);
      modal.show();
    }
  }
});
</script>
