import { h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router'
import Checklist from '@/views/checklist.vue';
import ChecklistDelete from '@/views/checklist-delete.vue';
import ChecklistNew from '@/views/checklist-new.vue';
import ChecklistSharedJoin from '@/views/checklist-shared-join.vue';
import store from '@/store';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: { render() { return h('div', "routing error") } } /* dummy component, should not be reachable */,
      beforeEnter: (_to, _from, next) => {
        const currentChecklistId = store.state.currentChecklistId;

        if (currentChecklistId) {
          next({ name: 'checklist', params: { id: currentChecklistId } });
        } else if (store.state.checklists.length > 0) {
          next({ name: 'checklist', params: { id: store.state.checklists[0].id } });
        } else {
          next({ name: 'checklist-new' });
        }
      },
    },
    {
      path: '/checklists/new',
      name: 'checklist-new',
      component: ChecklistNew,
    },
    {
      path: '/checklists/:id',
      name: 'checklist',
      component: Checklist,
      props: true,
      beforeEnter: (to, _from, next) => {
        const checklistId = to.params.id;
        const checklist = store.state.checklists.find((checklist2) => checklist2.id.toString() === checklistId);

        if (checklist) {
          store.dispatch("setCurrentChecklist", { checklistId: checklistId });
          next();
        } else {
          store.dispatch("setCurrentChecklist", { checklistId: null });
          next({ name: 'home' });
        }
      },
    },
    {
      path: '/checklists/:id/delete',
      name: 'checklist-delete',
      component: ChecklistDelete,
      props: true,
    },
    {
      path: '/checklists/shared/:token/join',
      name: 'checklist-shared-join',
      component: ChecklistSharedJoin,
      props: true,
    },
    {
      path: '/:pathMatch(.*)',
      redirect: '/',
    },
  ],
});

export default router
