import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      online: navigator.onLine
    };
  },
  methods: {
    updateOnlineStatus(e: any) {
      const { type } = e;
      this.online = type === 'online';
    }
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  }
});
