<template>
  <!-- class 'd-flex' is necessary so that scrollbars for each category slide are not app global -->
  <div class="app d-flex container">
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>
.app {
  height: 100vh; /* for full height carousel */
  padding: 75px 0px 15px 0px;
}
</style>

<style>
.icon {
  font-size: 125%;
  vertical-align: text-bottom;
}

.dropdown-toggle-no-caret::after {
  display: none !important;
}
</style>
