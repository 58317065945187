<template>
  <nav-bar>
    <router-link v-if="manyChecklists" class="btn btn-outline-secondary btn-sm" :to="{ name: 'home' }">
      <span class="h4">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-chevron-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
      </span>
    </router-link>

    <div class="navbar-brand mx-auto">New Checklist</div>
  </nav-bar>

  <div class="container-fluid">
    <form @submit.prevent="createChecklistAsync">
      <input
        class="form-control"
        type="text"
        v-model="title"
        required
        v-focus
        autocomplete="off"
        placeholder="Title of new checklist"
      />

      <div class="mt-3">
        <button class="btn btn-primary" type="submit">Create</button>
        <button v-if="manyChecklists" class="btn btn-secondary ms-3" type="button" @click="$router.push({ name: 'home' })">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import NavBar from '@/components/nav-bar.vue';
import { ref } from 'vue';
import store from '@/store';
import router from '@/router';

const title = ref('');
const manyChecklists = ref(store.state.checklists.length > 0);

async function createChecklistAsync() {
  if (title.value !== "") {
    const checklistId = await store.dispatch("addChecklist", {
      title: title,
    });

    router.push({
      name: "checklist",
      params: { id: checklistId.toString() },
    });
  }
}
</script>
