<template>
  <button class="btn btn-outline-secondary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebar">
    <span class="h4">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-list" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
      </svg>
    </span>
  </button>

  <div class="offcanvas offcanvas-start" style="max-width:70%" tabindex="-1" id="sidebar">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">My Checklists</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
    </div>
    <div class="offcanvas-body px-0">
      <checklist-sidebar :active-id="checklist.id"></checklist-sidebar>
    </div>
  </div>

  <div class="navbar-brand text-center">
    {{ checklist.title }}
    <span v-if="checklist.shared" class="ps-2" :class="{ 'text-danger': checklist.shared.syncFailed }">
      <svg v-if="online" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom" :data-bs-content="'Last successful sync: ' + checklist.shared.lastSyncTime" :class="{ 'text-info': checklist.shared.syncing, 'cloud-icon-sync': checklist.shared.syncing }" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" style="vertical-align: -0.15em" class="bi bi-cloud" viewBox="0 0 16 16">
        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
      </svg>
      <svg v-else data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom" data-bs-content="You are offline." xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" style="vertical-align: -0.15em" class="bi bi-cloud-slash" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M3.112 5.112a3.125 3.125 0 0 0-.17.613C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13H11l-1-1H3.781C2.231 12 1 10.785 1 9.318c0-1.365 1.064-2.513 2.46-2.666l.446-.05v-.447c0-.075.006-.152.018-.231l-.812-.812zm2.55-1.45-.725-.725A5.512 5.512 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773a3.2 3.2 0 0 1-1.516 2.711l-.733-.733C14.498 11.378 15 10.626 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3c-.875 0-1.678.26-2.339.661z"/>
        <path d="m13.646 14.354-12-12 .708-.708 12 12-.707.707z"/>
      </svg>
    </span>

    <div v-if="checklist.categories.length > 1" class="dropdown d-block fst-italic">
      <button type="button" class="btn border-0 dropdown-toggle fst-italic" data-bs-toggle="dropdown">
        <span class="me-1">
          <!-- zero width space upfront, so the div height does not collapse if empty -->
          &#8203;{{
            checklist.categories.find(
              (category) => category.id == checklist.currentCategoryId
            )?.name
          }}
        </span>
      </button>

      <div class="dropdown-menu">
        <a
          v-for="category in checklist.categories"
          :key="category.id"
          @click="changeCurrentCategory(category.id)"
          class="dropdown-item"
          href="#"
        >
          &#8203;{{ category.name }}
        </a>
      </div>
    </div>

    <div
      v-show="checklist.categories.length > 1"
      id="swiper-pagination"
    ></div>
  </div>

  <form>
    <!-- Clear items -->
    <button v-if="!checklist.hideCheckedItems" type="button" class="btn btn-outline-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#clear-items-modal">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-eraser" viewBox="0 0 16 16">
        <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"/>
      </svg>
    </button>

    <Modal id="clear-items-modal" title="Clear items" @ok="clearItems">
      All checked items will be deleted.
      <template v-slot:modal-ok>
        <span class="pe-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-eraser" viewBox="0 0 16 16">
            <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"/>
          </svg>
        </span>
        Clear
      </template>
    </Modal>

    <div class="dropdown btn-group ms-3" @click.prevent="">
      <button type="button" class="btn dropdown-toggle btn-outline-secondary btn-sm dropdown-toggle-no-caret" data-bs-toggle="dropdown">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-tools" viewBox="0 0 16 16">
          <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z"/>
        </svg>
      </button>

      <div class="dropdown-menu dropdown-menu-end">
        <!-- Show checked items temporarily, only if hideCheckedItems setting is true in checklist -->
        <a v-if="checklist.hideCheckedItems" href="#" @click="toggleHideCheckedItems" class="dropdown-item">
          <span class="pe-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-eye" viewBox="0 0 16 16">
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
            </svg>
          </span>
          Show checked
        </a>

        <!-- Hide checked items temporarily, only if hideCheckedItems setting is true in checklist -->
        <a v-if="!checklist.hideCheckedItems" href="#" @click="toggleHideCheckedItems" class="dropdown-item">
          <span class="pe-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-eye-slash" viewBox="0 0 16 16">
              <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
              <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
              <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
            </svg>
          </span>
          Hide checked
        </a>

        <!-- New Category -->
        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#newCategoryModal">
          <span class="pe-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-plus" viewBox="0 0 16 16">
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
          </span>
          New category
        </a>

        <!-- Share -->
        <a class="dropdown-item" href="#" @click="share(true)">
          <span class="pe-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-share" viewBox="0 0 16 16">
              <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
            </svg>
          </span>
          Share
        </a>

        <!-- Duplicate -->
        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#duplicateModal">
          <span class="pe-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-files" viewBox="0 0 16 16">
              <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
            </svg>
          </span>
          Duplicate
        </a>

        <div class="dropdown-divider"></div>

        <!-- Delete -->
        <a class="dropdown-item text-danger" href="#" data-bs-toggle="modal" data-bs-target="#deleteModal">
          <span class="pe-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
          </span>
          Delete
        </a>
      </div>
    </div>

    <!--
      ******
      Modals
      ******
      -->

    <!-- New Category -->
    <Modal id="newCategoryModal" title="New category" @ok="newCategory">
      <p>Enter name of new category:</p>
      <input
        class="form-control"
        type="text"
        v-model="categoryName"
        autocomplete="off"
        placeholder="Name of new category"
        required
      />
      <template v-slot:modal-ok>
        <span class="pe-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-plus" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
          </svg>
        </span>
        New
      </template>
    </Modal>

    <!-- Share -->
    <Modal id="shareModal" ref="shareModal" title="Upload" @ok="share(false)">
      You are sharing the first time. Need to upload.
      <template v-slot:modal-ok>
        <span class="pe-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-share" viewBox="0 0 16 16">
            <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
          </svg>
        </span>
        Upload
      </template>
    </Modal>

    <!-- Duplicate -->
    <Modal id="duplicateModal" title="Duplicate" @ok="duplicate">
      <p>Enter title of checklist duplicate:</p>
      <input
        class="form-control"
        type="text"
        v-model="duplicateTitle"
        autocomplete="off"
        placeholder="Title of checklist duplicate"
        required
      />
      <template v-slot:modal-ok>
        <span class="pe-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-files" viewBox="0 0 16 16">
            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
          </svg>
        </span>
        Duplicate
      </template>
    </Modal>

    <!-- Delete -->
    <Modal id="deleteModal" title="Delete checklist" okVariant="danger" @ok="deleteLocal">
      The checklist will be deleted.
      <template v-slot:modal-ok>
        <span class="pe-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="icon bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </span>
        Delete
      </template>
    </Modal>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ConnectionStatusMixin from '@/mixins/connection-status';
import ChecklistMixin from '@/mixins/checklist';
import { Popover } from 'bootstrap';
import ChecklistSidebar from '@/components/checklist-sidebar.vue';
import { Offcanvas } from 'bootstrap';
import type { Checklist } from '@/models/checklist';

export default defineComponent({
  components: { ChecklistSidebar },
  mixins: [ ConnectionStatusMixin, ChecklistMixin ],
  data: function() {
    return {
      categoryName: "",
      duplicateTitle: ""
    };
  },
  methods: {
    clearItems() {
      this.$store.dispatch("clearChecklist", {
        checklistId: this.checklist.id,
        categoryId: this.checklist.currentCategoryId,
      });
      this.sync();
    },

    changeCurrentCategory(categoryId: number) {
      this.$store.dispatch("changeCurrentCategory", {
        checklistId: this.checklist.id,
        currentCategoryId: categoryId,
      });
    },

    toggleHideCheckedItems() {
      this.$store.dispatch("checklistToggleHideCheckedItems", { checklistId: this.checklist.id });
    },

    deleteLocal() {
      this.$router.push({ name: "checklist-delete", params: { id: this.checklist.id } });
    },

    async newCategory() {
      const categoryId = await this.$store.dispatch("addChecklistCategory", {
        checklistId: this.checklist!.id,
        categoryName: this.categoryName,
      });

      this.changeCurrentCategory(categoryId);
      this.categoryName = "";
    },

    async share(interactive: boolean) {
      if (!this.checklist!.shared && interactive) {
        const modal: any = this.$refs.shareModal;
        modal.show();
        return;
      }

      if (!this.checklist!.shared) {
        await this.$store.dispatch("shareChecklist", {
          checklistId: this.checklist!.id,
        });
      }

      const nav = navigator;
      if (nav.share) {
        const token = this.checklist!.shared!.lastSyncedVersion.token!;

        const props = this.$router.resolve({
          name: "checklist-shared-join",
          params: { token: token },
        });

        nav
          .share({
            title: this.checklist!.title,
            text: "Share Checklist",
            url: props.href,
          })
          .catch((error) => alert("Error sharing " + error));
      }
    },

    async duplicate() {
      const checklistId = await this.$store.dispatch("duplicateChecklist", {
        title: this.duplicateTitle,
        checklistId: this.checklist!.id
      });

      this.$router.push({
        name: "checklist",
        params: { id: checklistId.toString() },
      });
      this.duplicateTitle = "";
    }
  },

  mounted() {
    document
      .querySelectorAll('[data-bs-toggle="popover"]')
      .forEach(el => new Popover(el));
  },

  watch: {
    id(id) {
      Offcanvas.getInstance('#sidebar')?.hide();
    },
    online(v) {
      if (v) {
        // TODO: only trigger sync if there were modifications / needsSync
        this.sync();
      }
    }
  }
});
</script>

<style scoped>
#swiper-pagination {
  position: unset;
  line-height: 10px;
}
</style>
<style>
.swiper-pagination-bullet {
  margin: 0px 1px !important;
}

.swiper-pagination-bullet:first-child {
  background-color: orange;
}

.cloud-icon-sync {
  animation: animated-cloud 1.0s infinite;
}

@keyframes animated-cloud {
  0% { transform: scale(1.0); }
  50% { transform: scale(0.5); }
  100% { transform: scale(1.0); }
}
</style>
