import { createStore } from 'vuex';
import type { Model } from '@/models/model';
import type { ChecklistDto } from '@/models/checklist-dto';
import type { ItemDto } from '@/models/item-dto';
import type { CategoryDto } from '@/models/category-dto';
import { merge } from '@/merge';
import type { Checklist } from '@/models/checklist';

const loadedState = localStorage.getItem('state');

let model: Model;

if (loadedState) {
  model = JSON.parse(loadedState);

  // migrate version 1 -> 2
  if (model.version == 1) {
    model.checklists.forEach(checklist => {
      if (checklist.hideCheckedItems === undefined) {
        checklist.hideCheckedItems = false;
      }

      checklist.items.concat(checklist.shared?.lastSyncedVersion.items ?? []).forEach(item => {
        if (item.createdAt === undefined) {
          item.createdAt = new Date(0).toJSON();
        }

        if ((item as any).checked === true) {
          item.checked = new Date(0).toJSON();
        } else if ((item as any).checked === false) {
          item.checked = null;
        }
      });
    });

    model.version = 2;
  }

  // TODO improve
  // reset syncing states to false, in case the app quit while syncing
  // the correct solution would be not to persist the syncing flag at all
  model.checklists.forEach(checklist => {
    if (checklist.shared) {
      checklist.shared.syncing = false;
    }
  });
} else {
  model = {
    version: 2,
    nextChecklistId: 1,
    currentChecklistId: null,
    checklists: [],
  };
}

const store = createStore({
  state: model,

  mutations: {
    ADD_CHECKLIST(state, payload: { title: string }) {
      state.checklists.push({
        id: state.nextChecklistId,
        title: payload.title,
        nextItemId: 1,
        nextCategoryId: 2,
        currentCategoryId: 1,
        hideCheckedItems: false,
        items: [],
        categories: [
          { id: 1, name: '' }
        ],
        shared: null,
      });

      state.nextChecklistId++;
    },

    CHANGE_CURRENT_CATEGORY(state, payload: { checklistId: number; currentCategoryId: number }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId);

      if (checklist) {
        checklist.currentCategoryId = payload.currentCategoryId;
      } else {
        throw new Error('Checklist not found, id: ' + payload.checklistId);
      }
    },

    ADD_CHECKLIST_ITEM(state, payload: { checklistId: number; categoryId: number; itemName: string }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId);

      if (checklist) {
        checklist.items.push({ id: checklist.nextItemId, name: payload.itemName, checked: null, highlighted: null, categoryId: payload.categoryId, createdAt: new Date().toJSON() });
        checklist.nextItemId++;
      } else {
        throw new Error('Checklist not found, id: ' + payload.checklistId);
      }
    },

    ADD_CHECKLIST_CATEGORY(state, payload: { checklistId: number; categoryName: string }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId);

      if (checklist) {
        checklist.categories.push({ id: checklist.nextCategoryId, name: payload.categoryName });
        checklist.nextCategoryId++;
      } else {
        throw new Error('Checklist not found, id: ' + payload.checklistId);
      }
    },

    RENAME_CATEGORY(state, payload: { checklistId: number; categoryId: number; newCategoryName: string }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId);

      if (checklist) {
        checklist.categories.find(category => (category.id == payload.categoryId))!.name = payload.newCategoryName;
      } else {
        throw new Error('Checklist not found, id: ' + payload.checklistId);
      }
    },

    TOGGLE_CHECKLIST_ITEM(state, payload: { checklistId: number; itemId: number }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId);

      if (checklist) {
        const item = checklist.items.find((item2) => item2.id === payload.itemId);

        if (item) {
          item.checked = item.checked != null ? null : new Date().toJSON();
        } else {
          throw new Error('Item not found, id: ' + payload.itemId);
        }
      } else {
        throw new Error('Checklist not found, id: ' + payload.checklistId);
      }
    },

    TOGGLE_HIGHLIGHT_CHECKLIST_ITEM(state, payload: { checklistId: number; itemId: number }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId);

      if (checklist) {
        const item = checklist.items.find((item2) => item2.id === payload.itemId);

        if (item) {
          item.highlighted = item.highlighted != null ? null : new Date().toJSON();
        } else {
          throw new Error('Item not found, id: ' + payload.itemId);
        }
      } else {
        throw new Error('Checklist not found, id: ' + payload.checklistId);
      }
    },

    CLEAR_CHECKLIST(state, payload: { checklistId: number; categoryId: number }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId);

      if (checklist) {
        checklist.items = checklist.items.filter(i => i.checked == null || (payload.categoryId != 1 && i.categoryId != payload.categoryId));
      } else {
        throw new Error('Checklist not found, id: ' + payload.checklistId);
      }
    },

    DELETE_CHECKLIST(state, payload: { checklistId: number }) {
      state.checklists = state.checklists.filter((checklist2) => checklist2.id !== payload.checklistId);
    },

    SET_CHECKLIST_LAST_SYNC(state, payload: { checklistId: number; checklistDto: ChecklistDto }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId);

      if (checklist) {
        const now = new Date();
        const lastSyncTime = now.toLocaleDateString() + " " + now.toLocaleTimeString();

        if (!checklist.shared) {
          checklist.shared = {
            syncFailed: false,
            syncing: false,
            lastSyncTime: lastSyncTime,
            lastSyncedVersion: payload.checklistDto
          };
        } else {
          checklist.shared.syncFailed = false;
          checklist.shared.lastSyncTime = lastSyncTime;
          checklist.shared.lastSyncedVersion = payload.checklistDto;
        }

        checklist.title = payload.checklistDto.title;
        checklist.items = payload.checklistDto.items.map(item => ({...item}));
        checklist.nextItemId = 1 + Math.max(0, ...(payload.checklistDto.items.map((item: ItemDto) => item.id)));
        checklist.categories = payload.checklistDto.categories.map(category => ({...category}));
        checklist.nextCategoryId = 1 + Math.max(0, ...(payload.checklistDto.categories.map((category: CategoryDto) => category.id)));
      } else {
        throw new Error('Checklist not found, id: ' + payload.checklistId);
      }
    },

    SET_SYNC_FAILED(state, payload: { checklistId: number }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId);
      checklist!.shared!.syncFailed = true;
    },

    SET_SYNCING(state, payload: { checklistId: number; syncing: boolean }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId);
      checklist!.shared!.syncing = payload.syncing;
    },

    CHECKLIST_MOVE_UP(state, payload: { checklistId: number }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId) as Checklist;
      const oldIndex = state.checklists.indexOf(checklist);

      if (oldIndex > 0) {
        const tmp = state.checklists[oldIndex - 1];
        state.checklists[oldIndex - 1] = checklist;
        state.checklists[oldIndex] = tmp;
      }
    },

    CHECKLIST_MOVE_DOWN(state, payload: { checklistId: number }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId) as Checklist;
      const oldIndex = state.checklists.indexOf(checklist);

      if (oldIndex < (state.checklists.length - 1)) {
        const tmp = state.checklists[oldIndex + 1];
        state.checklists[oldIndex + 1] = checklist;
        state.checklists[oldIndex] = tmp;
      }
    },

    CHECKLIST_TOGGLE_HIDE_CHECKED_ITEMS(state, payload: { checklistId: number }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId);
      checklist!.hideCheckedItems = !checklist?.hideCheckedItems;
    },

    SET_CURRENT_CHECKLIST(state, payload: { checklistId: number | null }) {
      state.currentChecklistId = payload.checklistId;
    },

    DUPLICATE_CHECKLIST(state, payload: { title: string; checklistId: number }) {
      const checklist = state.checklists.find((checklist2) => checklist2.id === payload.checklistId)!;

      state.checklists.push({
        id: state.nextChecklistId,
        title: payload.title,
        nextItemId: checklist.nextItemId,
        nextCategoryId: checklist.nextCategoryId,
        currentCategoryId: checklist.currentCategoryId,
        hideCheckedItems: checklist.hideCheckedItems,
        items: checklist.items.map(item => ({ ...item })),
        categories: checklist.categories.map(category => ({ ...category })),
        shared: null,
      });

      state.nextChecklistId++;
    },
  },

  actions: {
    addChecklist(context, payload: { title: string }) {
      const checklistId = context.state.nextChecklistId;
      context.commit('ADD_CHECKLIST', payload);
      return checklistId;
    },

    changeCurrentCategory(context, payload: { checklistId: number; currentCategoryId: number }) {
      context.commit('CHANGE_CURRENT_CATEGORY', payload);
    },

    addChecklistItem(context, payload: { checklistId: number; categoryId: number; itemName: string }) {
      context.commit('ADD_CHECKLIST_ITEM', payload);
    },

    addChecklistCategory(context, payload: { checklistId: number; categoryName: string }) {
      const checklist = context.state.checklists.find((checklist2) => checklist2.id === payload.checklistId);
      const categoryId = checklist!.nextCategoryId;
      context.commit('ADD_CHECKLIST_CATEGORY', payload);
      return categoryId;
    },

    renameCategory(context, payload: { checklistId: number; categoryId: number; newCategoryName: string }) {
      context.commit('RENAME_CATEGORY', payload);
    },

    toggleChecklistItem(context, payload: { checklistId: number; itemId: number }) {
      context.commit('TOGGLE_CHECKLIST_ITEM', payload);
    },

    toggleHighlightChecklistItem(context, payload: { checklistId: number; itemId: number }) {
      context.commit('TOGGLE_HIGHLIGHT_CHECKLIST_ITEM', payload);
    },

    setChecklistLastSync(context, payload: { checklistId: number; checklistDto: ChecklistDto }) {
      context.commit('SET_CHECKLIST_LAST_SYNC', payload);
    },

    clearChecklist(context, payload: { checklistId: number; categoryId: number }) {
      context.commit('CLEAR_CHECKLIST', payload);
    },

    deleteChecklist(context, payload: { checklistId: number }) {
      context.commit('DELETE_CHECKLIST', payload);
    },

    async syncChecklist(context, payload: { checklistId: number }) {
      const checklist = context.state.checklists.find((checklist2) => checklist2.id === payload.checklistId);

      // disallow multiple simultaneous syncs for same checklist
      if (checklist!.shared!.syncing) {
        return;
      }

      context.commit('SET_SYNCING', { checklistId: payload.checklistId, syncing: true });

      const lastSyncDto: ChecklistDto = checklist!.shared!.lastSyncedVersion;
      const newSyncDto: ChecklistDto =
        await fetch('/api/v2/checklists/' + checklist?.shared?.lastSyncedVersion.token)
          .then(response => response.json())
          .catch(() => null);

      if (newSyncDto == null)
      {
        context.commit('SET_SYNC_FAILED', { checklistId: payload.checklistId });
        context.commit('SET_SYNCING', { checklistId: payload.checklistId, syncing: false });
        return;
      }

      const getLocalDto = function () { return { title: checklist!.title, items: checklist!.items.map(item => ({ ...item })), categories: checklist!.categories.map(category => ({ ...category })), token: lastSyncDto.token, revision: lastSyncDto.revision }; };

      const localDto: ChecklistDto = getLocalDto();
      const localDtoJson = JSON.stringify(localDto); // serialize before merge, because merge changes its input

      const mergedDto = merge(lastSyncDto, localDto, newSyncDto);
      fetch(
        '/api/v2/checklists/' + checklist?.shared?.lastSyncedVersion.token,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(mergedDto),
        })
           .then(response => response.json())
           .then(async data => {
             const localDto2: ChecklistDto = getLocalDto();
             if (localDtoJson != JSON.stringify(localDto2)) {
               // do not overwrite changed checklist and start new sync
               // (pretend the uploaded/put change was external/remote)
               context.commit('SET_SYNCING', { checklistId: payload.checklistId, syncing: false });
               await this.dispatch("syncChecklist", { checklistId: payload.checklistId });
             } else {
               await this.dispatch("setChecklistLastSync", { checklistId: payload.checklistId, checklistDto: data });
               context.commit('SET_SYNCING', { checklistId: payload.checklistId, syncing: false });
             }
           }, () => {
             context.commit('SET_SYNC_FAILED', { checklistId: payload.checklistId });
             context.commit('SET_SYNCING', { checklistId: payload.checklistId, syncing: false });
           });
    },

    async shareChecklist(context, payload: { checklistId: number }) {
      const checklist = context.state.checklists.find((checklist2) => checklist2.id === payload.checklistId);
      const newDto: ChecklistDto = { title: checklist!.title, items: checklist!.items, categories: checklist!.categories };
      await fetch(
        '/api/v2/checklists',
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(newDto),
        })
      .then(response => response.json())
      .then(data => {
        this.dispatch("setChecklistLastSync", { checklistId: payload.checklistId, checklistDto: data });
      }, () => {
       // failed
       alert('whoops');
      });
    },

    checklistMoveUp(context, payload: { checklistId: number }) {
      context.commit('CHECKLIST_MOVE_UP', payload);
    },

    checklistMoveDown(context, payload: { checklistId: number }) {
      context.commit('CHECKLIST_MOVE_DOWN', payload);
    },

    checklistToggleHideCheckedItems(context, payload: { checklistId: number }) {
      context.commit('CHECKLIST_TOGGLE_HIDE_CHECKED_ITEMS', payload);
    },

    setCurrentChecklist(context, payload: { checklistId: number | null }) {
      context.commit('SET_CURRENT_CHECKLIST', payload);
    },

    duplicateChecklist(context, payload: { title: string; checklistId: number }) {
      const checklistId = context.state.nextChecklistId;
      context.commit('DUPLICATE_CHECKLIST', payload);
      return checklistId;
    },
  },
});

// persist every state change
store.subscribe((mutation, state) => {
  localStorage.setItem('state', JSON.stringify(state));
});

export default store;
