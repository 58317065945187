import { createApp } from 'vue'
import App from './app.vue';
import router from './router';
import store from './store';
import Modal from './components/Modal.vue';
import focus from './directives/focus';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

const app = createApp(App)

app.use(router)
app.use(store)
app.component('Modal', Modal)
app.directive('focus', focus)

app.mount('#app')
