<template>
  Deleting checklist {{ id }}.
</template>

<script setup lang="ts">
import store from '@/store';
import { defineProps, onMounted } from "vue";
import { useRouter } from 'vue-router'

const props = defineProps({
  id: String,
});

onMounted(async () => {
  const router = useRouter();
  await store.dispatch("deleteChecklist", { checklistId: Number(props.id) });
  router.push({ name: 'home' });
});
</script>
