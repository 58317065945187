<template>
  <!--
    little hack here:
      class 'container' is necessary so that navbar background does not have full width
  -->
  <nav class="navbar bg-light fixed-top container">
    <div class="container">
      <slot></slot>
    </div>
  </nav>
</template>

<script setup lang="ts">
</script>
